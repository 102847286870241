import React, { useEffect } from 'react';

const RedirectToEtsy = () => {
  useEffect(() => {
    window.location.href = 'https://antiquewhiteart.etsy.com';
  }, []);

  return null; // The component doesn't need to render anything
};

export default RedirectToEtsy;
