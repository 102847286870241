import React from 'react';
import { useParams } from 'react-router-dom';
import FileList from './components/FileList';
import './App.css';

function App() {
  const { folderId } = useParams();

  return (
    <div className="App">
      <FileList folderId={folderId} />
    </div>
  );
}

export default App;
