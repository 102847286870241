import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, CircularProgress, Alert } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Import CheckCircleIcon
import PdfPlaceholder from '../assets/pdf-icon.svg';
import Logo from '../assets/logo.png';


const FileList = ({ folderId }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Track errors
  const [downloadingFiles, setDownloadingFiles] = useState({}); // Track downloading progress per file
  const [downloadedFiles, setDownloadedFiles] = useState({}); // Track files that have been downloaded

  // const baseURL = process.env.NODE_ENV === 'production' ? 'https://api.antiquewhiteart.art' : 'http://localhost:8080';
  const baseURL = 'https://api.antiquewhite.art';
  
  useEffect(() => {
    console.log("FolderID: " + folderId);
    if (!folderId) {
      console.log("redirecting");
      window.location.href = 'https://antiquewhiteart.etsy.com';
      return;
    }

    axios.get(`${baseURL}/files/${folderId}`)
      .then(response => {
        setFiles(response.data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 404) {
            setError('The page was not found.');
          } else if (error.response.status === 500) {
            setError('There has been an issue with the files for this order. Please contact us via email contact@antiquewhite.art or Etsy messages at antiquewhiteart.etsy.com for more details.');
          } else {
            setError('An unexpected error X1790B occurred. Please try again later.');
          }
        } else {
          setError('An unexpected error X1776A occurred. Please try again later.');
        }
      });
  }, [folderId, baseURL]);

  const handleDownload = async (fileName) => {
    setDownloadingFiles(prevState => ({ ...prevState, [fileName]: true }));

    axios({
      url: `${baseURL}/files/download/${folderId}/${fileName}`,
      method: 'GET',
      responseType: 'blob',
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();

      // Mark file as downloaded
      setDownloadedFiles(prevState => ({ ...prevState, [fileName]: true }));
      // Reset download state after completion
      setDownloadingFiles(prevState => {
        const newState = { ...prevState };
        delete newState[fileName];
        return newState;
      });
    })
    .catch(error => {
      console.error('Error downloading file:', error);
      setDownloadingFiles(prevState => {
        const newState = { ...prevState };
        delete newState[fileName];
        alert('The file download failed. Please try again or contact info@domain.com for more details.');
        return newState;
      });
    });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress sx={{ color: '#E1D8D2' }} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen text-center">
        <Alert severity="error" sx={{ maxWidth: '600px', margin: '0 auto' }}>
          {error}
        </Alert>
      </div>
    );
  }

  return (
    <div className="w-full px-4 py-4" style={{ backgroundColor: '#F6F6F6' }}>
      <div className="flex items-center mb-4">
        <img src={Logo} alt="Logo" className="h-[4.5rem] w-[4.5rem] mr-4" />
        <h1 className="text-2xl font-bold flex-1 text-center">AntiqueWhiteArt Files</h1>
        <div className="h-[4.5rem] w-[4.5rem]"></div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {files.map(file => (
          <div key={file.name} className="relative p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 flex flex-col items-center">
            <div className="w-full aspect-w-3 aspect-h-2 mb-4 flex items-center justify-center bg-gray-100 rounded">
              {file.mimeType === 'application/pdf' ? (
                <img src={PdfPlaceholder} alt="PDF Thumbnail" className="object-cover w-full h-full" />
              ) : (
                <img
                  src={`data:${file.mimeType};base64,${file.thumbnailBase64}`}
                  alt={file.name}
                  className="object-cover w-full h-full rounded"
                />
              )}
            </div>
            <div className="text-center mb-4">
              <p className="text-lg font-medium">{file.name}</p>
            </div>
            <Button
              variant="contained"
              sx={{
                backgroundColor: downloadedFiles[file.name] ? '#7C6F5E' : '#7C6F5E',
                color: '#fff',
                '&:hover': {
                  backgroundColor: downloadedFiles[file.name] ? '#A19179' : '#A19179',
                },
              }}
              startIcon={downloadedFiles[file.name] ? <CheckCircleIcon /> : <DownloadIcon />}
              onClick={() => handleDownload(file.name)}
              disabled={downloadingFiles[file.name] !== undefined || downloadedFiles[file.name] !== undefined}
            >
              {downloadedFiles[file.name] ? 'Downloaded' : 'Download'}
            </Button>
            {downloadingFiles[file.name] !== undefined && (
              <div className="absolute inset-0 bg-gray-800 bg-opacity-50 flex flex-col justify-center items-center rounded-lg">
                <CircularProgress sx={{ color: '#E1D8D2' }} />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FileList;
